<template>
  <base-modal
    modal-title="Download Basket"
    popup-class="modal-sm mb-16"
    disableClickOnOverlayClose="true"
    hideCloseButton="true"
    @hidden="resetTableData()"
  >
    <template #modalBody>
      <collapse-card
        class="px-8 py-4"
        :model-value="true"
        :disable-collapse="true"
        :show-collapse-button="false"
      >
        <template #title>
          <span class="mr-2 text-sm font-medium">
            Basket Items
          </span>
        </template>
        <template #cardContent>
          <div class="px-2 py-3 ">
            <base-table
              table-container-class="h-96 overflow-y-auto overflow-x-hidden overflow-x-hidden"
              class="min-w-full"
            >
              <template #tableHead>
                <thead class="bg-blue-100 border border-custom-gray-8">
                  <tr>
                    <th
                      v-for="(col, cIndex) in columns"
                      :key="'download-assets-table-head-column' + cIndex"
                      scope="col"
                      class="px-6 py-3 text-left text-sm font-semibold tracking-wider"
                    >
                      {{ col.name }}

                      <div
                        v-if="col.columnKey"
                        class="flex ml-1 mt-1"
                      >
                        <base-svg
                          class="h-3.5 w-3.5 inline-block cursor-pointer mx-1"
                          :class="col.allSelected ? 'text-primary-gray' : 'text-primary-red'"
                          src="icons/check.svg"
                          :svg-attributes="{
                            class: 'h-full w-full'
                          }"
                          tag="span"
                          @click="selectAllInColumn(col.columnKey)"
                        />
                        <base-svg
                          class="h-3.5 w-3.5 inline-block cursor-pointer mx-1"
                          :class="col.allSelected ? 'text-primary-red' : 'text-primary-gray'"
                          src="icons/cross.svg"
                          :svg-attributes="{
                            class: 'h-full w-full'
                          }"
                          tag="span"
                          @click="unselectAllInColumn(col.columnKey)"
                        />
                      </div>
                    </th>
                  </tr>
                </thead>
              </template>
              <template #tableBody>
                <tr
                  v-for="(basketItem, trIndex) in basketItems"
                  :key="'download-assets-table-body-row' + trIndex"
                  class="text-sm"
                  :class="{
                    'bg-custom-gray-6': trIndex % 2 !== 0,
                    'bg-custom-gray-11': trIndex % 2 === 0,
                    'border-t border-custom-gray-8': trIndex == 0,
                  }"
                >
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="w-16 mr-5">
                        <img
                          class="w-full"
                          :src="getThumbnailForAsset(basketItem)"
                        >
                      </div>
                      <div class="text-sm font-semibold truncate w-84">
                        {{ basketItem.name }}
                      </div>
                    </div>
                  </td>
                  <td
                    class="px-6 py-4 whitespace-nowrap"
                  >
                    <base-checkbox
                      v-model="selectedTransformersMap[`${basketItem.styleGuideAssetId}--Thumbnail`]"
                      :disabled="!transformAvailableForItem('Thumbnail', basketItem) || !basketItem.canDownload"
                      container-class="flex items-center mr-5"
                      :value="`${basketItem.styleGuideAssetId}-Thumbnail`"
                      @change="handleSelected({ objectId: `${basketItem.styleGuideAssetId}`, name: 'Thumbnail', size: basketItem.fileSize }, $event)"
                    >
                      <template #label>
                        <label
                          :class="!transformAvailableForItem('Thumbnail', basketItem) ? 'opacity-20': ''"
                          class="ml-2 text-sm"
                        >
                          Thumbnail
                        </label>
                      </template>
                    </base-checkbox>
                  </td>
                  <td
                    class="px-6 py-4 whitespace-nowrap"
                  >
                    <base-checkbox
                      v-model="selectedTransformersMap[`${basketItem.styleGuideAssetId}--Preview`]"
                      :disabled="!transformAvailableForItem('Preview', basketItem) || !basketItem.canDownload"
                      container-class="flex items-center mr-5"
                      :value="`${basketItem.styleGuideAssetId}-Preview`"
                      @change="handleSelected({ objectId: `${basketItem.styleGuideAssetId}`, name: 'Preview', size: basketItem.fileSize }, $event)"
                    >
                      <template #label>
                        <label
                          :class="!transformAvailableForItem('Preview', basketItem) ? 'opacity-20': ''"
                          class="ml-2 text-sm"
                        >
                          Preview
                        </label>
                      </template>
                    </base-checkbox>
                  </td>
                  <td
                    class="px-6 py-4 whitespace-nowrap"
                  >
                    <base-checkbox
                      v-model="selectedTransformersMap[`${basketItem.styleGuideAssetId}--Web`]"
                      :disabled="!transformAvailableForItem('Web', basketItem) || !basketItem.canDownload"
                      container-class="flex items-center mr-5"
                      :value="`${basketItem.styleGuideAssetId}-Web`"
                      @change="handleSelected({ objectId: `${basketItem.styleGuideAssetId}`, name: 'Web', size: basketItem.fileSize }, $event)"
                    >
                      <template #label>
                        <label
                          :class="!transformAvailableForItem('Web', basketItem) ? 'opacity-20': ''"
                          class="ml-2 text-sm"
                        >
                          Web
                        </label>
                      </template>
                    </base-checkbox>
                  </td>
                  <td
                    class="px-6 py-4 whitespace-nowrap"
                  >
                    <base-checkbox
                      v-model="selectedTransformersMap[`${basketItem.styleGuideAssetId}--Half`]"
                      :disabled="!transformAvailableForItem('Half', basketItem) || !basketItem.canDownload"
                      container-class="flex items-center mr-5"
                      :value="`${basketItem.styleGuideAssetId}-Half`"
                      @change="handleSelected({ objectId: `${basketItem.styleGuideAssetId}`, name: 'Half', size: basketItem.fileSize }, $event)"
                    >
                      <template #label>
                        <label
                          :class="!transformAvailableForItem('Half', basketItem) ? 'opacity-20': ''"
                          class="ml-2 text-sm"
                        >
                          Half Scale
                        </label>
                      </template>
                    </base-checkbox>
                  </td>
                  <td
                    class="px-6 py-4 whitespace-nowrap"
                  >
                    <base-checkbox
                      v-model="selectedTransformersMap[`${basketItem.styleGuideAssetId}--Original`]"
                      :disabled="!transformAvailableForItem('Original', basketItem) || !basketItem.canDownload"
                      container-class="flex items-center mr-5"
                      :value="`${basketItem.styleGuideAssetId}-Original`"
                      @change="handleSelected({ objectId: `${basketItem.styleGuideAssetId}`, name: 'Original', size: basketItem.fileSize }, $event)"
                    >
                      <template #label>
                        <label
                          :class="!transformAvailableForItem('Original', basketItem) ? 'opacity-20': ''"
                          class="ml-2 text-sm"
                        >
                          Original {{ printFileSize(basketItem.fileSize) }}
                        </label>
                      </template>
                    </base-checkbox>
                  </td>
                </tr>
              </template>
            </base-table>
            <div
              class="flex items-center"
              :class="downloadLimitExceeded || (directDownloadResults && directDownloadResults.length) ? 'justify-between' : 'justify-end'"
            >

              <div v-if="downloadLimitExceeded" class="text-sm text-primary-red mr-8 mt-4">
                You have exceeded the max download limit of <strong>{{ printFileSize(maxDownloadLimit) }}</strong>.
                Please de-select some assets to enable downloading.
              </div>

              <div v-if="directDownloadResults && directDownloadResults.length" class="text-sm text-primary-gray mr-8 mt-4">
                Download job is in progress. Please wait for the <b>Active Downloads</b> to complete.
              </div>

              <div class="mt-4">
                <base-button
                  class="mr-2"
                  variant="btn-primary"
                  :disabled="downloadLimitExceeded || !totalDownloadSize || (directDownloadResults && directDownloadResults.length)"
                  :text="'Download' + (totalDownloadSize ? ` ${printFileSize(totalDownloadSize)} (Approx)` : '')"
                  @click="handleDownload()"
                />
                <base-button
                  variant="btn-border-gray-8"
                  text="Close"
                  :disabled="!isDirectDownloadJobFinished"
                  @click="handleCancel()"
                />
              </div>
            </div>
          </div>
        </template>
      </collapse-card>

      <!--
      <collapse-card
        v-if="Object.keys(fileTracker).length"
        class="px-8 pb-4"
        :model-value="true"
      >
        <template #title>
          <span class="mr-2 text-sm font-medium">
            Active Downloads
          </span>
        </template>
        <template #cardContent>
          <div class="h-48 overflow-y-auto overflow-x-hidden">
            <div
              v-for="(tracker, name, idx) in fileTracker"
              :key="idx"
              class="m-4 flex flex-row"
            >
              <div class="font-semibold font-mono p-2 max-w-5xl w-4/5 bg-custom-gray-2 truncate tracking-wider mr-1">
                [{{ idx }}] {{ name }}
              </div>
              <div
                class="font-semibold text-right p-2 w-1/5 "
                :class="tracker.error ? 'bg-red-200' : downloadedPercentage(tracker) >= 100 ? 'bg-green-200' : downloadedPercentage(tracker) > 0 ? 'bg-custom-yellow-1' : 'bg-custom-gray-2'"
              >
                <span
                  v-if="tracker.error"
                  class="text-primary-red"
                >ERROR</span>
                <span v-else>{{ downloadedPercentage(tracker) }} % completed</span>
              </div>
            </div>
          </div>

          <div class="mt-4 flex items-center justify-end">
            <base-button
              class="mr-2"
              variant="btn-primary"
              text="Remove Completed"
              @click="resetFileTracker(true)"
            />
            <base-button
              variant="btn-border-gray-8"
              text="Clear All"
              @click="resetFileTracker()"
            />
          </div>
        </template>
      </collapse-card>
      -->

      <collapse-card
        v-if="directDownloadResults.length"
        class="px-8 pb-4"
        :model-value="true"
      >
        <template #title>
          <span class="mr-2 text-sm font-medium">
            Active Downloads
          </span>
        </template>
        <template #cardContent>
          <div class="h-48 overflow-y-auto overflow-x-hidden">
            <div v-for="(dlMessage, idx) in directDownloadResults" :key="idx" class="m-4 flex flex-row">
              <div
                class="font-semibold font-mono p-2 w-full truncate tracking-wider mr-1"
                :class="
                  dlMessage.startsWith('Error') ? 'bg-red-200 text-primary-red' :
                  dlMessage.startsWith('Completed') ? 'bg-green-200' :
                  dlMessage.startsWith('Downloading') ? 'bg-custom-yellow-1' :
                  'bg-custom-gray-2'
                "
              >
                {{ dlMessage }}

                <base-svg v-if="dlMessage.startsWith('Downloading')"
                  class="h-4 w-4 mr-1 text-yellow-700 inline-block ml-8"
                  src="icons/circleSpinner.svg"
                  tag="span"
                />

              </div>
            </div>
          </div>

          <div class="mt-4 flex items-center justify-end">
            <base-button
              v-if="!isDirectDownloadJobFinished"
              variant="btn-primary"
              text="Cancel Job"
              @click="cancelDirectDownloadJob()"
            />
            <base-button
              v-else
              variant="btn-border-gray-8"
              text="Clear"
              @click="resetDirectDownloadResult()"
            />
          </div>
        </template>
      </collapse-card>

    </template>
  </base-modal>
</template>

<script>
import { computed, defineAsyncComponent, ref } from 'vue';
import { useStore } from 'vuex';
import useToastNotifications from '@/hooks/toastNotifications.js';
import { printFileSize } from '@/helpers/util';

import useDownloadManager from './downloadManager';

export default {
    name: 'DownloadBasketModal',

    components: {
        CollapseCard: defineAsyncComponent(() => import('@/components/CollapseCard.vue')),
        BaseModal: defineAsyncComponent(() => import('@/components/generic-components/BaseModal.vue')),
        BaseTable: defineAsyncComponent(() => import('@/components/generic-components/BaseTable.vue')),
        BaseCheckbox: defineAsyncComponent(() => import('@/components/generic-components/BaseCheckbox.vue')),
        BaseSvg: defineAsyncComponent(() => import('@/components/generic-components/BaseSvg.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue'))
    },

    emits: [
        'hide'
    ],

    setup (props, { emit }) {
        const store = useStore();
        const { notificationsStack } = useToastNotifications();

        const basketItems = computed(() => store.getters['basket/getBasketList']);

        const selectedTransformers = ref([]);

        const resetTableData = () => {
            selectedTransformers.value.splice(0, selectedTransformers.value.length);
        };

        const handleCancel = () => {
          resetDirectDownloadResult()
          emit('hide')
        };

        const columns = ref([
            {
                name: 'File Name',
                showSelectButtons: false,
                columnKey: null,
                allSelected: null
            },
            {
                name: '128px',
                showSelectButtons: true,
                columnKey: 'Thumbnail',
                allSelected: false
            },
            {
                name: '512px',
                showSelectButtons: true,
                columnKey: 'Preview',
                allSelected: false
            },
            {
                name: '1500px',
                showSelectButtons: true,
                columnKey: 'Web',
                allSelected: false
            },
            {
                name: '300dpi',
                showSelectButtons: true,
                columnKey: 'Half',
                allSelected: false
            },
            {
                name: 'Hi Res',
                showSelectButtons: true,
                columnKey: 'Original',
                allSelected: false
            }
        ]);

        const maxDownloadLimit = ref(5 * 1024 * 1024 * 1024); // 5 GB

        const selectedTransformersMap = computed(() => {
            const tmp = {};
            selectedTransformers.value.map(e => tmp[`${e.objectId}--${e.name}`] = true);
            return tmp;
        });

        const totalDownloadSize = computed(() => {
            let tmp = 0;
            selectedTransformers.value.forEach(e => tmp += e.size);
            return tmp;
        });

        const downloadLimitExceeded = computed(() => totalDownloadSize.value > maxDownloadLimit.value);

        const getSizeForTransform = (transformName, fileSize) => {
            let tmp = fileSize;
            switch (transformName) {
            case 'Thumbnail': tmp = 15 * 1024; break; // 15K ...... // OLD -  fileSize * 0.0175; break;
            case 'Preview': tmp = 140 * 1024; break; // 140K ...... // OLD -  fileSize * 0.05; break;
            case 'Web': tmp = 1010 * 1024; break; // 1010K ...... // OLD -  fileSize * 0.2; break;
            case 'Half': tmp = 3030 * 1024; break; // 3030K ...... // OLD -  fileSize * 0.4; break;
            default: break;
            }
            return tmp;
        };

        const selectAllInColumn = (transformName) => {
            if (!transformName) return;

            // console.log(`Checking for ${transformName} .. `)

            basketItems.value
                .filter(basketItem => {
                    const tmp = transformAvailableForItem(transformName, basketItem);
                    // console.log(`Checking for ${basketItem.name} .. ${transformName} .. ${tmp}`)
                    return tmp;
                })
                .map(basketItem => {
                    if (Object.keys(selectedTransformersMap.value).includes(
                        `${basketItem.styleGuideAssetId}--${transformName}`
                    )) return;

                    selectedTransformers.value.push({
                        objectId: basketItem.styleGuideAssetId,
                        name: transformName,
                        size: getSizeForTransform(transformName, basketItem.fileSize)
                    });
                });
        };

        const unselectAllInColumn = (transformName) => {
            if (!transformName) return;
            selectedTransformers.value = selectedTransformers.value.filter(e => e.name !== transformName);
        };

        const handleSelected = (value, chkBox) => {
            const isAdding = chkBox.target.checked;
            if (isAdding) {
                value.size = getSizeForTransform(value.name, value.size);
                selectedTransformers.value.push(value);
            } else {
                const idx = selectedTransformers.value.findIndex(e => e.objectId === value.objectId && e.name === value.name);
                if (idx >= 0) selectedTransformers.value.splice(idx, 1);
            }
        };

        const transformAvailableForItem = (transform, basketItem) => {
            const ext = basketItem.name.toLowerCase().split('.').pop();
            try {
                if (!basketItem || !basketItem.name || !basketItem.fileSize || !transform) return false;

                // No transform for the Metadata only asset
                if (basketItem.fileSize <= 0) return false;

                if (!ext) return false;

                const commonExtensions = ['psd', 'eps', 'gif', 'tiff', 'tif', 'png', 'jpeg', 'jpg', 'ai', 'pdf'];
                switch (transform) {
                case 'Thumbnail':
                    return [...commonExtensions].includes(ext);
                case 'Preview':
                    return [...commonExtensions].includes(ext);
                case 'Web':
                    return [...commonExtensions].includes(ext);
                case 'Half':
                    return [...commonExtensions].includes(ext);
                case 'Original':
                    return [...commonExtensions, 'zip', 'wav', 'mp3', 'aif', 'aiff', 'mp4', 'mov', 'm4v', 'avi'].includes(ext);
                default:
                    return false;
                }
            } catch (e) {
                console.log(`Problem in transformAvailableForItem ${transform} .. ${basketItem.styleGuideAssetId} .. ${ext} .. ${e}`);
            }
            return false;
        };

        const __type = ref('other');
        const getThumbnailForAsset = (basketItem) => {
            let tmpThumbnail = require('@/assets/images/upload/asset.png');
            if (basketItem?.name && basketItem.renditions.original) {
                const __rends = basketItem.renditions.original;
                const ext = basketItem?.name.toLowerCase().split('.').pop();
                if (ext) {
                    if (['wav', 'mp3', 'aif', 'aiff'].includes(ext)) {
                    // AUDIO
                        __type.value = 'audio';
                        tmpThumbnail = require('@/assets/images/upload/audio.png');
                    } else if (['mov', 'mp4', 'm4v', 'avi'].includes(ext)) {
                    // VIDEO
                        __type.value = 'video';
                        tmpThumbnail = require('@/assets/images/upload/video.png');
                        if (__rends.videoPreview && !__rends.videoPreview.includes('/700x700')) {
                            tmpThumbnail = __rends.videoPreview;
                        }
                    } else if (['pdf'].includes(ext)) {
                    // PDF
                        __type.value = 'pdf';
                        tmpThumbnail = require('@/assets/images/upload/pdf-small.png');
                        if (__rends.imagePreview && !__rends.imagePreview.includes('/700x700')) {
                            tmpThumbnail = __rends.imagePreview;
                        }
                    } else if (['zip'].includes(ext)) {
                    // ZIP
                        __type.value = 'zip';
                    } else {
                    // IMAGE
                        __type.value = 'image';
                        if (__rends.imagePreview && !__rends.imagePreview.includes('/700x700')) {
                            tmpThumbnail = __rends.imagePreview;
                        }
                    }
                }
            }
            return tmpThumbnail;
        };

        const currentUser = computed(() => store.getters['auth/getUserInfo']);

        const handleDownload = async () => {
            const objectIdMap = {};
            selectedTransformers.value.forEach(t => {
                if (!objectIdMap[t.objectId]) objectIdMap[t.objectId] = [];
                objectIdMap[t.objectId].push(t.name);
            });

            const getExtension = (filename) => filename.includes('.') ? filename.split('.').pop() : '';

            const linksToDownload = [];
            Object.keys(objectIdMap).forEach(objId => {
                const objTransforms = objectIdMap[objId] || [];
                const basketItem = basketItems.value.find(e => e.styleGuideAssetId === objId);
                const fileExt = getExtension(basketItem.name).toLowerCase();

                if (objTransforms.includes('Original')) {
                    let origPayload = {
                        __parent: 'original',
                        __name: `${basketItem.name}`,

                        __real_objectId: basketItem.styleGuideAssetId,
                        __authToken: currentUser.value.token
                    }

                    {
                      let url = basketItem.asgardMasterBaseUrl
                      if (!url) throw `Master Download URL is not available`

                      const masterData = {
                        fileId: basketItem.styleGuideFileId,
                        objectId: basketItem.styleGuideAssetId,
                        filename: basketItem.name,
                        licenseeIds: (store.getters['auth/getUserLicensees'] || []).map(l => l.licenseeId),
                        downloadType: 'Basket Download'
                      }
                      url += `?masterData=${encodeURIComponent(window.btoa(JSON.stringify(masterData)))}`
                      if ( (basketItem.wmFlag||'').toLowerCase().startsWith('y') ) url = `${url}&skipWatermark=true`

                      origPayload.__authLink = url
                    }

                    linksToDownload.push(origPayload);
                }

                if (fileExt) {
                    objTransforms.map(t => {
                        switch (t) {
                        case 'Thumbnail':
                            if (basketItem.renditions.transformations.thumbnail) linksToDownload.push({
                                __parent: 'thumbnail',
                                __name: `${basketItem.name}.thumbnail.jpg`,
                                __authLink: basketItem.renditions.transformations.thumbnail,
                                __authToken: currentUser.value.token
                            })
                            break;
                        case 'Preview':
                            if (basketItem.renditions.transformations.preview) linksToDownload.push({
                                __parent: 'preview',
                                __name: `${basketItem.name}.preview.jpg`,
                                __authLink: basketItem.renditions.transformations.preview,
                                __authToken: currentUser.value.token
                            });
                            break;
                        case 'Web':
                            if (basketItem.renditions.transformations.web) linksToDownload.push({
                                __parent: 'web',
                                __name: `${basketItem.name}.web.jpg`,
                                __authLink: basketItem.renditions.transformations.web,
                                __authToken: currentUser.value.token
                            });
                            break;
                        case 'Half':
                            if (basketItem.renditions.transformations.halfScale) linksToDownload.push({
                                __parent: 'half-scale',
                                __name: `${basketItem.name}.half-scale.jpg`,
                                __authLink: basketItem.renditions.transformations.halfScale,
                                __authToken: currentUser.value.token
                            });
                            break;
                        }
                    });
                }
            });

            /* --- FOR ZIPPED DOWNLOADS ---
            {
              // Add each file to tracker
              linksToDownload.map(l => initTrackerForName(`${l.__parent ? l.__parent + '/' : ''}${l.__name}`));

              // Submit the job
              submitDownloadJob({
                  dowloadJobName: `asgard-download-${Date.now()}`,
                  linksToDownload,
                  store
              });
            }
            */

            {
              // Direct Download to the folder .. NO ZIP
              let exportId = `ASGARD-${Date.now() - 1669050900000}`
              let theBroswer = browserName (window.navigator.userAgent.toLowerCase())

              directDownloadResults.value = [`Starting Export Job : ${exportId}`]
              directDownloadJobStopped.value = false

              for (let l = 0; l < linksToDownload.length && !directDownloadJobStopped.value; l++) try {
                const tmpLink = linksToDownload[l]
                let { __link, __parent, __name, __authToken, __authLink, __objectId, __real_objectId } = tmpLink;
                let name = `${exportId ? exportId+'-' : ''}${__name}`;
                directDownloadResults.value.unshift(`Downloading ${l+1} of ${linksToDownload.length} : ${__name}`)

                if (__authToken && (__authLink || __objectId)) {
                  let tmpResp
                  if (__objectId) tmpResp = await store.dispatch(
                    'upload/getDownloadAttachmentURL', { params: { id: __objectId, filename: __name, objectId: __real_objectId, exportId } }
                  )
                  if (__authLink) tmpResp = await store.dispatch(
                    'upload/getDownloadAttachmentURLViaUrl', { params: { url: `${__authLink}&exportId=${exportId}` }}
                  )
                  __link = tmpResp.presignedURL || tmpResp.presignedUrl
                }

                // Get the url and fetch the stream
                if (!__link) throw new Error(`No link found for ${__name} .. Skipping download`)

                /* Using Anchor HTML tag .. then click .. works in webkit based browsers */
                else if (['chrome', 'edge', 'edge chromium', 'other'].includes(theBroswer)) {
                  let a = document.createElement('a');
                  a.download = name;
                  try {
                    document.body.appendChild(a)

                    a.href = __link;
                    a.click()

                    const tmpMessage = directDownloadResults.value.shift()
                    if (tmpMessage) directDownloadResults.value.unshift(tmpMessage.replace('Downloading ', 'Completed - '))
                  } catch (dlErr) {
                    const tmpMessage = directDownloadResults.value.shift()
                    if (tmpMessage) directDownloadResults.value.unshift(tmpMessage.replace('Downloading ', 'Error while downloading - '))
                  } finally {
                    document.body.removeChild(a)
                  }
                }

                /* Using Fetch : Needed for FF and Safari .. and adding the ASGARD EXPORT ID ..  */
                else if (['firefox', 'safari'].includes(theBroswer)) {
                  let a = document.createElement('a');
                  a.download = name
                  let blobUrl
                  try {
                    let res = await fetch(__link)
                    const fileData = await res.blob()
                    blobUrl = window.URL.createObjectURL(fileData)
                    a.href = blobUrl
                    a.click()

                    const tmpMessage = directDownloadResults.value.shift()
                    if (tmpMessage) directDownloadResults.value.unshift(tmpMessage.replace('Downloading ', 'Completed - '))
                  } catch (dlErr) {
                    console.error(dlErr)
                    const tmpMessage = directDownloadResults.value.shift()
                    if (tmpMessage) directDownloadResults.value.unshift(tmpMessage.replace('Downloading ', 'Error while downloading - '))
                  } finally {
                    a.remove()
                    if (blobUrl) window.URL.revokeObjectURL(blobUrl)
                  }
                }

                // For every requests, wait for 300 milliseconds
                await new Promise(resolve => { setTimeout(resolve, 300) })

              } catch (linkErr) {
                directDownloadResults.value.unshift(`Error while downloading - ${linksToDownload[l].__name}`)
              }

              if (directDownloadJobStopped.value) directDownloadResults.value.unshift(`Job Finished : User cancelled the download request for ${exportId}`)
              else directDownloadResults.value.unshift(`Job Finished : ${exportId}`)

              // Clear the messages if there are no errors and user did not cancel the job
              if (!directDownloadJobStopped.value && !directDownloadResults.value.some(m => m.startsWith('Error')) ){
                setTimeout(resetDirectDownloadResult, 1000)
              }

            }


        };

        const downloadedPercentage = (tracker) => {
            if (!tracker || !tracker.loaded || !tracker.total) return 0;
            return Math.round(tracker.loaded / tracker.total * 100);
        };

        const directDownloadResults = ref([])
        const resetDirectDownloadResult = () => directDownloadResults.value = []
        const directDownloadJobStopped = ref(false)
        const cancelDirectDownloadJob = () => directDownloadJobStopped.value = true
        const isDirectDownloadJobFinished = computed(() => {
          let tmp = [ ...directDownloadResults.value ]
          if (!tmp || !tmp.length) return true
          else if (tmp[0].startsWith('Job Finished')) return true
          else return false
        })

        const browserName = (agent) => {
          switch (true) {
            case agent.indexOf("edge") > -1: return "edge";
            case agent.indexOf("edg/") > -1: return "edge chromium";
            case agent.indexOf("opr") > -1 && !!window.opr: return "opera";
            case agent.indexOf("chrome") > -1 && !!window.chrome: return "chrome";
            case agent.indexOf("trident") > -1: return "ie";
            case agent.indexOf("firefox") > -1: return "firefox";
            case agent.indexOf("safari") > -1: return "safari";
            default: return "other";
          }
        }

        const { submitDownloadJob, initTrackerForName, resetFileTracker, fileTracker } = useDownloadManager();

        return {
            basketItems,
            selectedTransformersMap,
            columns,
            selectAllInColumn,
            unselectAllInColumn,
            totalDownloadSize,
            handleSelected,
            resetTableData,
            handleCancel,
            handleDownload,
            downloadLimitExceeded,
            maxDownloadLimit,
            printFileSize,
            transformAvailableForItem,
            getThumbnailForAsset,
            downloadedPercentage,
            fileTracker,
            resetFileTracker,
            directDownloadResults,
            resetDirectDownloadResult,
            cancelDirectDownloadJob,
            isDirectDownloadJobFinished
        };
    }
};
</script>
